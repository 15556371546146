import { Box, Container, Grid, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { images } from "../../constants/assets";
import Text from "../common/Text";
import { services } from "./Home/OurServicesSection";


const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(10, 0),
        backgroundColor: theme.palette.custom.textPrimary,

        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(6, 0),
        },
        position: 'relative'
    },
    footerLogoSubtitle: {
        marginTop: 8,
        color: theme.palette.common.white,
        lineHeight: '40px'
    },
    colTitle: {
        color: '#92989F',
        fontWeight: 600
    },
    colList: {
        marginTop: 32,
        '& .MuiTypography-root, & a': {
            marginBottom: 22,
            color: theme.palette.common.white,
            display: 'block'
        }
    },
    logo: {
        maxWidth: '3rem',
        height: 'auto',

        [theme.breakpoints.down('sm')]: {
            maxWidth: '2.25rem'
        }
    }
}))

const Footer = () => {
    const classes = useStyles()
    return (
        <Box className={classes.root} component='footer'>
            <Container>
                <Grid container spacing={6}>
                    <Grid item xs={12} md={4}>
                        <img className={classes.logo} src={images.FullLightLogo} alt='logo' />
                        <Text className={classes.footerLogoSubtitle} color='white'>An entertainment platform that keeps <br /> all your video production services in <br /> one easy-to-manage place.</Text>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Text className={classes.colTitle}>Services</Text>
                        <Box className={classes.colList}>
                            {
                                services.map(({ title }) => <Text key={title}>{title}</Text>)
                            }
                        </Box>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Text className={classes.colTitle}>Follow us</Text>
                        <Box className={classes.colList}>
                            <a href='https://www.linkedin.com/company/theproreal/' target='_blank' rel="noreferrer">LinkedIn</a>
                            <a href='https://twitter.com/ETheproreal?t=NCZGHKfePlSXcUQ2UtqtHQ&s=09' rel="noreferrer" target='_blank'>Twitter</a>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Text className={classes.colTitle}>Contact us</Text>
                        <Box className={classes.colList}>
                            <a href='mailto:techsupport@theproreal.com' target='_blank'>techsupport@theproreal.com</a>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default Footer