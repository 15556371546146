interface IEnv {
    WITH_REDUX_DEVTOOLS: string,
    APP_REDUX_KEY: string,
    ADMIN_BACKEND_BASE_URL: string
    CLIENT_BACKEND_BASE_URL: string
    RAZORPAY_KEY: string
    STRAPI_API_TOKEN: string
    STRAPI_HOST_URL: string
}

const env: IEnv = {
    WITH_REDUX_DEVTOOLS: String(process.env.REACT_APP_WITH_REDUX_DEVTOOLS),
    APP_REDUX_KEY: String(process.env.REACT_APP_APP_REDUX_KEY),
    ADMIN_BACKEND_BASE_URL: String(process.env.REACT_APP_BACKEND_BASE_URL),
    CLIENT_BACKEND_BASE_URL: String(process.env.REACT_APP_CLIENT_BACKEND_BASE_URL),
    RAZORPAY_KEY: String(process.env.REACT_APP_RAZORPAY_KEY),
    STRAPI_API_TOKEN: String(process.env.REACT_APP_STRAPI_API_TOKEN),
    STRAPI_HOST_URL: String(process.env.REACT_APP_STRAPI_HOST_URL)
}

export default env;