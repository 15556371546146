import AdFree from '../assets/AdFree.svg';
import ContactTextOutline from '../assets/contact-outline.svg';
import { ReactComponent as DizzinessFilled } from '../assets/dizziness.svg';
import Download from '../assets/download.svg';
import Facebook from '../assets/Facebook.svg';
import FeaturedPlayer from '../assets/featured-player.svg';
import { ReactComponent as Funds } from '../assets/fund.svg';
import Empty from '../assets/images/empty.svg';
import NotFound from '../assets/images/not_found.svg';
import { ReactComponent as Inauguration } from '../assets/inauguration.svg';
import Instagram from '../assets/Instagram.svg';
import LinkedIn from '../assets/LinkedIn.svg';
import { default as FullDarkLogo, default as Logo } from '../assets/logo-black.svg';
import FullLightLogo from '../assets/logo-white.svg';
import { ReactComponent as UsersBigGroup } from '../assets/many-group.svg';
import { ReactComponent as MoneyBag } from '../assets/money-bag.svg';
import RadialGlow from '../assets/radial-glow.svg';
import { ReactComponent as Rocket } from '../assets/rocket.svg';
import SeamlessStreaming from '../assets/seamless-streaming.svg';
import SparkGroup1 from '../assets/spark-group-1.svg';
import SparkGroup2 from '../assets/spark-group-2.svg';
import Twitter from '../assets/Twitter.svg';
import { ReactComponent as UsersSmallGroup } from '../assets/user-group.svg';
import FullLogo from '../assets/zeron-logo-white.svg';
export const images = {
    MoneyBag,
    Funds,
    Rocket,
    UsersBigGroup,
    UsersSmallGroup,
    Inauguration,
    NotFound,
    Empty,
    Logo,
    FullLightLogo,
    FullDarkLogo,
    FullLogo,
    RadialGlow,
    SparkGroup1,
    SparkGroup2,
    AdFree,
    Download,
    FeaturedPlayer,
    SeamlessStreaming,
    Facebook,
    Instagram,
    Twitter,
    LinkedIn,
    ContactTextOutline
}

export const icons = {
    DizzinessFilled
}