import { AnyAction } from "redux";
import authConstants from "../constants/auth";

interface IAuthState {
    token: string | null;
    refreshToken: string | null;
    roleId: string | null;
    userId: string | null;
    permissions: string[];
    tokenExpiryTimestamp: number | null;
    workspaceMetaData: {
        bitmovinApiKey: string | null;
        bitmovinOrgId: string | null;
        accessKey: string | null;
        secretKey: string | null;
        bucketName: string | null;
    }
}

const initialState: IAuthState = {
    token: null,
    refreshToken: null,
    roleId: null,
    userId: null,
    permissions: [],
    tokenExpiryTimestamp: null,
    workspaceMetaData: {
        bitmovinApiKey: null,
        bitmovinOrgId: null,
        accessKey: null,
        secretKey: null,
        bucketName: null,
    }
}

const authReducer = (state = initialState, action: AnyAction): IAuthState => {
    switch (action.type) {
        case authConstants.USER_AUTHENTICATE:
            return {
                ...state,
                ...action.payload
            }

        case authConstants.USER_LOGOUT:
            return initialState
    
        default:
            return state;
    }
}

export default authReducer;