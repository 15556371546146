import { Box, Theme } from "@mui/material"
import { makeStyles } from "@mui/styles"
import clsx from 'clsx'
import { FC } from 'react'
import Text from '../../common/Text'

interface StyleProps {
    textWhite?: boolean
    rotateTitleHeading?: boolean
}

const useStyles = makeStyles<Theme, StyleProps>(theme => ({
    root: {
        position: 'relative',
        display: ({ rotateTitleHeading }) => rotateTitleHeading ? 'flex' : 'block',
    },
    heroTitle: {
        fontSize: 60,
        fontWeight: 700,
        lineHeight: '78px',
        color: ({ textWhite }) => theme.palette.common[textWhite ? 'white' : 'black'],

        [theme.breakpoints.down('md')]: {
            fontSize: 32,
            lineHeight: '48px'
        }
    },
    heroTitleHeading: {
        alignItems: 'center',
        transform: ({ rotateTitleHeading }) => rotateTitleHeading ? 'rotate(-90deg)' : 'none',
        display: ({ rotateTitleHeading }) => rotateTitleHeading ? 'inline-flex' : 'flex',
        flexDirection: ({ rotateTitleHeading }) => rotateTitleHeading ? 'row-reverse' : 'row',

        '&  .line': {
            width: 72,
            marginRight: 16,
            height: 1,
            backgroundColor: ({ textWhite }) => textWhite ? theme.palette.common.white : theme.palette.custom.textPrimary
        },
        '& .text': {
            fontSize: 20,
            textTransform: 'uppercase',
            marginRight: 16,
            color: ({ textWhite }) => theme.palette.common[textWhite ? 'white' : 'black']
        },

        [theme.breakpoints.down('md')]: {
            '&  .line': {
                width: 48,
            },
            '& .text': {
                fontSize: 14,
            },
        }
    },
}))

interface Props {
    headingTitle?: string
    titleLine1: string
    titleLine2?: string
    textWhite?: boolean
    rotateTitleHeading?: boolean
    align?: "right" | "left" | "inherit" | "center" | "justify" | undefined
    hideTitleHeadingLine?: boolean
}

const SectionTitle: FC<Props> = ({
    headingTitle,
    titleLine1,
    titleLine2,
    textWhite,
    rotateTitleHeading,
    align,
    hideTitleHeadingLine
}) => {
    const classes = useStyles({
        textWhite,
        rotateTitleHeading
    })

    return (
        <Box className={classes.root}>
            {
                headingTitle &&
                <Box justifyContent={align === 'center' ? 'center' : 'flex-start'} className={clsx(classes.heroTitleHeading, 'heroTitleHeading')}>
                    {
                        !hideTitleHeadingLine &&
                        <div className='line' />
                    }
                    <Text className="text">{headingTitle}</Text>
                </Box>
            }
            <Text align={align} className={classes.heroTitle}>{titleLine1} {titleLine2 && <br />} { titleLine2}</Text>
        </Box>
    )
}

export default SectionTitle