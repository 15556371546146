const USER_AUTHENTICATE = "USER_AUTHENTICATE";
const USER_REGISTER = "USER_REGISTER";
const USER_LOGOUT = "USER_LOGOUT";
const SET_TOKEN_EXPIRY_TIMESTAMP = "SET_TOKEN_EXPIRY_TIMESTAMP";
const TOKEN_REFRESH = "TOKEN_REFRESH"


const authConstants = {
	USER_AUTHENTICATE,
	USER_REGISTER,
	USER_LOGOUT,
	SET_TOKEN_EXPIRY_TIMESTAMP,
	TOKEN_REFRESH
};

export default authConstants