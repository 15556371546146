import { nanoid } from "nanoid"
import { Dispatch } from "redux"
import { appHistory } from "../../components/router/AppRouter"
import permissions from "../../constants/permissions"
import routes from "../../constants/routes"
import { login } from "../../services/users"
import { ILoginFormData } from "../../types"
import { authenticateAction, logoutAction } from "../actions/authActions"
import { clearPrivateMasterdataAction } from "../actions/masterdataActions"


const tempLogin = (dispatch: Dispatch) => {
        const userAuthData = {
            jwt: 'eyJhbGciOiJSUzI1NiJ9.eyJzdWIiOiIyMDIyMzMzNzU5MjQxTDdMUVlNMEsxMEJpZ0IiLCJyb2xlIjpbeyJhdXRob3JpdHkiOiJjYW5DcmVhdGUifSx7ImF1dGhvcml0eSI6ImNhblVwZGF0ZSJ9LHsiYXV0aG9yaXR5IjoiY2FuRGVsZXRlIn1dLCJleHAiOjE2NDY5NTI2MjcsImlhdCI6MTY0NjkxNjYyN30.qFiUGz4IVfaW0dFDNgvpOBvPHvgWRQwiIYLee8gkRh-Z_wDzTztnFm66F6ulaQSobMQW1goHGd1L8aep8o9u8mbeWxlDBv1f3c1t2PTtlN8-9UCY0GXfN0NBw0341lYXSfi6DINGRCZAmfwXxEBJS1rgbqwZfl7p2H8PPQz0DKwC4WrNy5J3uOEIX4ntznJ_z9jBfc7yOA7rx8mymuvvUFLK-ls-8FNYkU0z0UaZN69PQi-7oKTxFPh2bCWRnEGZK93BiltaFhLIqGVE2x51sGCW9Ef_Xn8n2GWRFTo90kP5Ro1s8YFoGnf10yfPqhPhV2I38Vty7-wHJ6INHBaqXg',
            refreshToken: 'hj345ghjasdasdwer2123sdf',
            roleId: '1',
            permissions: [
                ...Object.values(permissions.forPages),
                ...Object.values(permissions.forSidebar),
            ],
            userId: 'US001',
            tokenExpiryTimestamp: (new Date()).getTime()
        }
        dispatch(authenticateAction(userAuthData))
        appHistory.push(routes.privatePages.DashboardPage.url())
}

export const loginDispatcher = (formData: ILoginFormData, onSuccess = () => {}, onFailure = () => {}) => {
    return async (dispatch: Dispatch) => {

        // tempLogin(dispatch)
        
        const { error, data } = await login({ 
            ...formData,
            device: JSON.stringify({ 
                type: 'Desktop',
                id: nanoid()
            }),
        })

        if (error) {
            console.log(error.body);
            onFailure()
            return;
        }

        if (data) {
            onSuccess();            

            const userAuthData = {
                jwt: data.payload.jwt,
                refreshToken: data.payload.refreshToken,
                roleId: data.payload.userEntity.roleId,
                permissions: [
                    ...Object.values(permissions.forPages),
                    ...Object.values(permissions.forSidebar),
                ],
                userId: data.payload.userEntity.id,
                tokenExpiryTimestamp: (new Date()).getTime() + data.payload.expiration_time         // logout after current time + time set in backend
            }
            dispatch(authenticateAction(userAuthData))
            appHistory.push(routes.privatePages.DashboardPage.url())
        }
    }
}

export const logoutDispatcher = (nextPageUrl: string = routes.publicPages.HomePage.url()) => {
    return async (dispatch: Dispatch) => {
        // logout the user
        dispatch(logoutAction())

        // clear all user related private masterdata
        dispatch(clearPrivateMasterdataAction())

        // redirect to homepage
        appHistory.push(nextPageUrl)
    }
}