import { Dispatch } from "redux";
import { getAllEvents } from "../../services/event";
import { getAllActivePlans } from "../../services/planBilling";
import { IPrivateMasterdataState } from "../../types";
import { privateMasterdataAction } from "../actions/masterdataActions";

const privateMasterdataDispatcher = () => {
    return async (dispatch: Dispatch) => {
        try {
            // fetching all private master data
            const allPrivateMasterdata = await Promise.resolve([
                (await getAllActivePlans('PLAN', 100)),
                (await getAllEvents()),
            ])

            // setting up private data
            const finalMasterdataForRedux: IPrivateMasterdataState = {
                allPlans: allPrivateMasterdata[0].data?.payload,
                allEvents: allPrivateMasterdata[1].data?.payload,
            }

            dispatch(privateMasterdataAction(finalMasterdataForRedux));
        } catch (error) {
            console.log('error occured while fetching private masterdata', error);
        }
    }
}


export default privateMasterdataDispatcher