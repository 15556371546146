import DashboardIcon from '@mui/icons-material/Dashboard';
import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import permissions from '../../../constants/permissions';
import routes from '../../../constants/routes';
import { getUuid } from '../../../utils/others';

export interface IMenuGenerator {
    key: string
    label: string;
    icon: OverridableComponent<SvgIconTypeMap<{}, "svg">> | null | undefined;
    permissionKey: string;
    children: IMenuGenerator[]
    pathname: string | null
}

class MenuGenerator implements IMenuGenerator {
    key: string
    label: string;
    icon:OverridableComponent<SvgIconTypeMap<{}, "svg">> | null | undefined
    permissionKey: string
    children: IMenuGenerator[]
    pathname: string | null
    
    constructor (
        label: string,
        pathname:string | null = null,
        icon: OverridableComponent<SvgIconTypeMap<{}, "svg">> | null | undefined = null,
        permissionKey: string,
        children:IMenuGenerator[] = [],
    ) {
        this.key = getUuid();
        this.label = label;
        this.icon = icon;
        this.permissionKey = permissionKey;
        this.children = children;
        this.pathname = pathname;
    }
}

const navMenuList = [
    new MenuGenerator('General', null, null, permissions.forSidebar.General, [
        new MenuGenerator('Dashboard', routes.privatePages.DashboardPage.url(), DashboardIcon, permissions.forPages.DashboardPage),
    ]),
]

export default navMenuList