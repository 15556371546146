import authConstants from "../constants/auth";

interface IAuthenticateActionData {
    jwt: string | null;
    refreshToken: string | null;
    roleId: string | null;
    userId: string | null;
    permissions: string[],
    tokenExpiryTimestamp: number | null
}

export const authenticateAction = (data: IAuthenticateActionData) => {
    return {
        type: authConstants.USER_AUTHENTICATE,
        payload: {
            token: data.jwt,
            refreshToken: data.refreshToken,
            roleId: data.roleId,
            permissions: data.permissions,
            userId: data.userId,
            tokenExpiryTimestamp: data.tokenExpiryTimestamp
        },
    }
}

export const logoutAction = () => {
    return {
        type: authConstants.USER_LOGOUT
    }
}




