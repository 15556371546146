import AccountCircle from '@mui/icons-material/AccountCircle';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import MenuIcon from '@mui/icons-material/Menu';
import { ClassNameMap } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import clsx from 'clsx';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { images } from '../../../constants/assets';
import { logoutDispatcher } from '../../../redux/dispatchers/authDispatchers';
import { darkModeToggleDispatcher } from '../../../redux/dispatchers/themeDispatchers';
import { RootState } from '../../../types';

interface Props {
    classes: ClassNameMap<string>,
    openSidebar: () => void;
    sidebarIsOpen: boolean;
}

const AppNavbar: React.FC<Props> = ({
    classes,
    openSidebar,
    sidebarIsOpen
}) => {
    const dispatch = useDispatch()
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);


    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleDarkModeToggle = () => {
        dispatch(darkModeToggleDispatcher(!isDarkMode))
    }


    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        handleClose()
        dispatch(logoutDispatcher())
    }

    return (
        <AppBar
            className={clsx(
                classes.appBar,
                {
                    [classes.appBarShift]: sidebarIsOpen,
                }
            )}
            elevation={0}
        >
            <Toolbar>
                {
                    !sidebarIsOpen ?
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                            onClick={openSidebar}
                        >
                            <MenuIcon />
                        </IconButton> : null
                }
                <div className={classes.navbarLogo}>
                    <img src={images.Logo} alt='full logo' />
                </div>
                {/* <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    Zeron
                </Typography> */}
                <div>
                    <IconButton title='toggle dark mode' aria-label='toggle dark mode' onClick={handleDarkModeToggle} color="inherit">
                        {isDarkMode ? <LightModeIcon /> : <DarkModeIcon />}
                    </IconButton>
                    <IconButton
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleMenu}
                        color="inherit"
                    >
                        <AccountCircle />
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        {/* <MenuItem onClick={handleClose}>Profile</MenuItem>
                        <MenuItem onClick={handleClose}>My account</MenuItem> */}
                        <MenuItem onClick={handleLogout}>Logout</MenuItem>
                    </Menu>
                </div>
            </Toolbar>
        </AppBar>

    )
}

export default AppNavbar
