import { Paper, StyledEngineProvider, ThemeProvider } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { darkTheme, lightTheme, offlineTheme } from '../../../styles/theme'
import { RootState } from '../../../types'
import Text from '../Text'
import CssBaseline from './CssBaseline'

const AppTheme: FC = ({ children }) => {
    const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);
    const [isOffline, setIsOffline] = useState(false)

    const handleAppOnline = () => {
        setIsOffline(false)
        toast.success((
            <>
                <Text style={{ color: offlineTheme.palette.grey[800] }} fontSize={14} fontWeight={600}>You're back online!</Text>
            </>
        ), {
            position: 'bottom-center'
        })
    }

    const handleAppOffline = () => {
        setIsOffline(true)
        toast.error((
            <>
                <Text style={{ color: offlineTheme.palette.grey[800] }} fontSize={14} fontWeight={600}>You're currently offline.</Text>
            </>
        ), {
            position: 'bottom-center'
        })
    }

    useEffect(() => {
        window.addEventListener('online', handleAppOnline)
        window.addEventListener('offline', handleAppOffline)

        return () => {
            window.removeEventListener('online', handleAppOnline)
            window.removeEventListener('offline', handleAppOffline)
        }
    }, [])


    return (
        <ThemeProvider theme={isOffline ? offlineTheme : (!isDarkMode ? lightTheme : darkTheme)}>
            <CssBaseline />
            <Paper square elevation={0} style={{ minHeight: '100vh' }}>
                <StyledEngineProvider injectFirst>
                    {children}
                </StyledEngineProvider>
            </Paper>
        </ThemeProvider>
    )
}

export default AppTheme
