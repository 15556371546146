import { stringifyUrl } from "query-string";
import Axios from "../interceptors/axiosInstance";
import Request from "../interceptors/Request";
import { IDateRange, IGeneralResponseData, ISearchEventDetailsData } from "../types";
import { formatDateTimeWithZeroOffset } from "../utils/datetime";

const EventService = new Request(Axios)

export const getAllEvents = () => {
    return EventService.post<null, IGeneralResponseData>('/Event/getAllEvents', null)
}

export const createEventDetails = (data: {
    email: string
    event: string
}) => {
    return EventService.post<typeof data, IGeneralResponseData>('/Event/createEventDetails', data)
}

export const getEventDetails = (data: ISearchEventDetailsData) => {
    data.createdAt = formatDateTimeWithZeroOffset(data.createdAt, 'startOf')
    return EventService.post<ISearchEventDetailsData, IGeneralResponseData>(stringifyUrl({ url: '/Event/getEventDetails', query: {...data} }), data)
}

export const getAllEventDetailsByRange = (data: IDateRange) => {
    data.fromDate = formatDateTimeWithZeroOffset(data.fromDate, 'startOf')
    data.toDate = formatDateTimeWithZeroOffset(data.toDate, 'endOf')
    return EventService.post<IDateRange, IGeneralResponseData>(stringifyUrl({ url: '/Event/getAllEventDetails', query: {...data} }), data)
}