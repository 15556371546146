import { CssBaseline as MuiCssBaseline, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../types';
import { darkenColor, fadeColor, lightenColor } from '../../../utils/styles';

interface Props {
    isDarkMode: Boolean
}

const useStyles = makeStyles<Theme, Props>(theme => ({
    '@global': {
        '*::-webkit-scrollbar': {
            width: "7px",
            height: "7px",
        },
        '*::-webkit-scrollbar-button': {
            width: "0",
            height: "0",
        },
        '*::-webkit-scrollbar-track': {
            background: theme.palette.background.paper,
            border: "0px none #ffffff",
            borderRadius: "0",
            '&:hover,&:active': {
                background: theme.palette.background.paper,
            }
        },
        '*::-webkit-scrollbar-track-piece': {

        },
        '*::-webkit-scrollbar-thumb': {
            background: lightenColor(theme.palette.primary.main, 0),
            border: "0px none #ffffff",
            borderRadius: 100
        },
        '*::-webkit-scrollbar-corner': {
            background: "transparent",
        },
        small: {
            color: "red",
        },
        a: {
            textDecoration: 'none'
        },
        InputBase: {
            color: theme.palette.grey[400],
        },

        '.MuiTouchRipple-child': {
            background: `linear-gradient(45deg, ${theme.palette.primary.main} ,${theme.palette.secondary.main})`,
            opacity: "1 !important",
        },

        '.MuiTouchRipple-root': {
            opacity: "1 !important",
        },

        ".MuiTabs-indicator": {
            background: fadeColor(theme.palette.primary.main, 0.4)
        },

        ".MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover": {
            background: `linear-gradient(90deg,${fadeColor(theme.palette.primary.main, 0.1)}, ${fadeColor(theme.palette.secondary.main, 0.1)})`,
            color: theme.palette.getContrastText(fadeColor(theme.palette.primary.main, 0.1))
        },

        '.MuiPopover-paper': {
            boxShadow: theme.shadows[2],
        },

        ".MuiFormControlLabel-root": {
            color: theme.palette.text.primary,
        },
        ".formGridMarginLeft": {
            marginLeft: theme.spacing(1.5),
        },
        '.sentry-error-embed-wrapper': {
            zIndex: '999999999 !important'
        },
        'input:-webkit-autofill,input:-webkit-autofill:hover,input:-webkit-autofill:focus,input:-webkit-autofill:active, input:-internal-autofill-selected, input:-internal-autofill-previewed': {
            '-webkit-box-shadow': '0 0 0 0px rgba(255,255,255,0) inset !important',
            backgroundColor: "rgba(255,255,255,0) !important",
            transitionDelay: '999999s !important',
        },
        '#nprogress .bar': {
            // background: `${darkenColor(theme.palette.primary.main, 0.25)} !important`,
            background: `linear-gradient(90deg,${fadeColor(theme.palette.primary.main, 0.1)}, ${fadeColor(theme.palette.secondary.main, 0.15)}) !important`,
            zIndex: '999999 !important',
            height: '2.5px !important'
        },
        '#nprogress .peg': {
            boxShadow: `0 0 10px ${theme.palette.secondary.main}, 0 0 5px ${theme.palette.secondary.main}  !important`
        },
        '#nprogress .spinner-icon': {
            display: 'none !important'
        },
        '.MuiTableRow-root': {
            '& .MuiTableCell-root': {
                fontSize: 12
                // padding: theme.spacing(0, 2),
                // border: `${theme.spacing(0.5)}px solid ${theme.palette.background.paper}`,
                // borderBottom: `none`,
                // backgroundColor: `${darkenColor(theme.palette.background.paper, 0.025)}`
            },
            '&.Mui-selected': {
                '& .MuiTableCell-root': {
                    backgroundColor: ({ isDarkMode }) => isDarkMode ? `${darkenColor(theme.palette.primary.main, 0.4)}` : `${lightenColor(theme.palette.primary.main, 0.4)}`,
                    color: ({ isDarkMode }) => isDarkMode ? theme.palette.getContrastText(darkenColor(theme.palette.primary.main, 0.4)) : theme.palette.getContrastText(lightenColor(theme.palette.primary.main, 0.4))
                }
            },
            '& .datatables-noprint': {
                padding: theme.spacing(1)
            },
        },
        'input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            '-moz-appearance': 'none',
            'appearance': 'none',
            'margin': 0,
        },
        '.swal2-container': {
            zIndex: '99999 !important'
        },
        '.MuiPaper-root': {
            transition: 'all 0.15s linear !important'
        },
        '*::-webkit-calendar-picker-indicator': {
            filter: ({ isDarkMode }) => `invert(${isDarkMode ? '1' : '0'})`
        },
        'p': {
            margin: 0
        },
        'a:active': {
            color: theme.palette.secondary.main
        }
    },
}));

const CssBaseline: React.FC = () => {
    const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode)
    const classes = useStyles({ isDarkMode })

    return (
        <MuiCssBaseline classes={classes} />
    )
}

export default CssBaseline
