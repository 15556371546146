import moment from "moment"

export const formatTimestampForTable = (timestamp: moment.MomentInput) => {
    return !!timestamp ? moment(timestamp).format('DD MMMM YYYY, hh:mm A') : '---'
}

export const formatDateTime = (timestamp: moment.MomentInput) => {
    return moment(timestamp).format('YYYY-MM-DDThh:mm')
}

export const formatDateTimeWithZeroOffset = (timestamp: moment.MomentInput, dayTime: 'startOf' | 'endOf') => {
    return moment(moment(timestamp)[dayTime]('day').toISOString(true)).format('YYYY-MM-DDTHH_mm_ss').concat(`.${dayTime === 'startOf' ? "000" : "999"}Z`)
}


export const formatOnlyDate = (timestamp: moment.MomentInput) => {
    return !!timestamp ? moment(timestamp).format('Do MMMM, YYYY') : '---'
}