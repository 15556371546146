import { Theme } from "@mui/material"
import { makeStyles } from "@mui/styles"

const useCommonStyles = makeStyles((theme: Theme) => ({
    width25: {
        width: '25%'
    },
    width50: {
        width: '50%'
    },
    width75: {
        width: '75%'
    },
    width100: {
        width: '100%'
    },
    vw25: {
        width: '25vw'
    },
    vw50: {
        width: '50vw'
    },
    vw75: {
        width: '75vw'
    },
    vw100: {
        width: '100vw'
    },
    height100: {
        height: '100%'
    },
    vh25: {
        height: '25vh'
    },
    vh50: {
        height: '50vh'
    },
    vh75: {
        height: '75vh'
    },
    vh100: {
        height: '100vh'
    },
    padding0: {
        padding: 0
    },
    paddingHorizontal2: {
        padding: theme.spacing(0, 2)
    },
    flex: {
        display: 'flex'
    },
    justifySpaceBetween: {
        justifyContent: 'space-between'
    },
    justifyEnd: {
        justifyContent: 'flex-end'
    },
    justifyCenter: {
        justifyContent: 'center'
    },
    alignCenter: {
        alignItems: 'center'
    },
    alignSelfCenter: {
        alignSelf: 'center'
    },
    alignEnd: {
        alignItems: 'flex-end'
    },
    marginLeft1: {
        marginLeft: theme.spacing(1)
    },
    marginVertical1: {
        margin: theme.spacing(1, 1)
    },
    marginVertical2: {
        margin: theme.spacing(2, 0, 2, 0)
    },
    flexColumn: {
        flexDirection: 'column'
    },
    flexRow: {
        flexDirection: 'row'
    },
    textCenter: {
        textAlign: 'center'
    },
    relative: {
        position: 'relative'
    },
    pointer: {
        cursor: 'pointer'
    },
    pageCenter: { 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '80vh', 
        width: '100%' 
    }
}))

export default useCommonStyles