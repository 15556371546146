import { lazy, LoadableComponent } from '@loadable/component';
import { IGeneratePathForRoutes } from "../types";
import { getUuid, replaceVariableWithValueInURL } from "../utils/others";
import permissions from './permissions';

// public pages
const HomePage = lazy(() => import("../pages/public/HomePage"));
const LoginPage = lazy(() => import("../pages/public/LoginPage"));
const PageNotFound = lazy(() => import('../pages/PageNotFound'));
const AllBlogsPage = lazy(() => import('../pages/public/Blogs/AllBlogsPage'));
const BlogDetailsPage = lazy(() => import('../pages/public/Blogs/BlogDetailsPage'));

// private pages
const DashboardPage = lazy(() => import('../pages/private/DashboardPage'))




export const crudType = {
    canCreate: 'canCreate',
    canRead: 'canRead',
    canUpdate: 'canUpdate',
    canDelete: 'canDelete',
}

class GeneratePathForRoutes implements IGeneratePathForRoutes {
    permissionKey: string;
    crudType: {} | null
    url: (values?: string[]) => string
    path: string;
    Component: LoadableComponent<{} & any>
    label: string | undefined
    isShownOnSidebar: boolean | undefined
    key: string | undefined
    props: {} | undefined
    icon: any;

    constructor(
        permissionKey: string,
        crudType: {} | null,
        url: string,
        Component: LoadableComponent<{} & any>,
        label?: string | undefined,
        isShownOnSidebar?: boolean | undefined,
        props?: {} | undefined
    ) {
        this.permissionKey = permissionKey
        this.crudType = crudType
        this.url = function (values = []) {
            const cleanUrl = url.includes('/:') ? url.split('/:')[0] : url
            return values.length === 0 ? cleanUrl : replaceVariableWithValueInURL(url, values)
        };
        this.path = url
        this.Component = Component
        this.label = label
        this.isShownOnSidebar = isShownOnSidebar
        this.key = getUuid();
        this.props = props
    }
}

const publicPages = {
    HomePage: new GeneratePathForRoutes(permissions.forPages.HomePage, null, '/', HomePage),
    AllBlogsPage: new GeneratePathForRoutes(permissions.forPages.AllBlogsPage, null, '/blogs', AllBlogsPage),
    BlogDetailsPage: new GeneratePathForRoutes(permissions.forPages.BlogDetailsPage, null, '/blogs/:id', BlogDetailsPage),
    LoginPage: new GeneratePathForRoutes(permissions.forPages.LoginPage, null, '/login', LoginPage),
    PageNotFound: new GeneratePathForRoutes(permissions.forPages.PageNotFound, null, '*', PageNotFound),
}

const privatePages = {
    HomePage: new GeneratePathForRoutes(permissions.forPages.HomePage, null, '/', HomePage),
    DashboardPage: new GeneratePathForRoutes(permissions.forPages.DashboardPage, null, '/dashboard', DashboardPage),

    // AllCustomPlanRequestsPage: new GeneratePathForRoutes(permissions.forPages.AllEnterprisePlansDataPage, null, '/custom-plan-requests', AllCustomPlanRequestsPage),

    PageNotFound: new GeneratePathForRoutes(permissions.forPages.PageNotFound, null, '*', PageNotFound),
}

const routes = {
    publicPages,
    privatePages
}

export default routes;