import React from 'react'
import { useSelector } from 'react-redux'
import { Router } from 'react-router'
import { Route } from 'react-router-dom'
import withAppErrorBoundary from '../../hoc/withAppErrorBoundary'
import withSuspenseWrapper from '../../hoc/withSuspenseWraper'
import { IGeneratePathForRoutes, RootState } from '../../types'
import ScrollToTop from '../common/ScrollToTop'
import PrivateRoutes from './PrivateRoutes'
import PublicRoutes from './PublicRoutes'

const history = require('history')

export const RenderRoute: React.FC<IGeneratePathForRoutes> = ({ key, path, Component, props }) => {
    return (
        <Route
            exact
            key={key}
            path={path}
            component={
                withAppErrorBoundary(
                    withSuspenseWrapper(Component, props)
                )
            }
        />
    )
}
export const appHistory = history.createBrowserHistory()

const AppRouter: React.FC = () => {
    const auth = useSelector((state: RootState) => state.auth)


    // temporary login
    // const dispatch = useDispatch()
    // React.useEffect(() => {
    //     const userAuthData = {
    //         jwt: 'eyJhbGciOiJSUzI1NiJ9.eyJzdWIiOiIyMDIyMzMzNzU5MjQxTDdMUVlNMEsxMEJpZ0IiLCJyb2xlIjpbeyJhdXRob3JpdHkiOiJjYW5DcmVhdGUifSx7ImF1dGhvcml0eSI6ImNhblVwZGF0ZSJ9LHsiYXV0aG9yaXR5IjoiY2FuRGVsZXRlIn1dLCJleHAiOjE2NDY5NTI2MjcsImlhdCI6MTY0NjkxNjYyN30.qFiUGz4IVfaW0dFDNgvpOBvPHvgWRQwiIYLee8gkRh-Z_wDzTztnFm66F6ulaQSobMQW1goHGd1L8aep8o9u8mbeWxlDBv1f3c1t2PTtlN8-9UCY0GXfN0NBw0341lYXSfi6DINGRCZAmfwXxEBJS1rgbqwZfl7p2H8PPQz0DKwC4WrNy5J3uOEIX4ntznJ_z9jBfc7yOA7rx8mymuvvUFLK-ls-8FNYkU0z0UaZN69PQi-7oKTxFPh2bCWRnEGZK93BiltaFhLIqGVE2x51sGCW9Ef_Xn8n2GWRFTo90kP5Ro1s8YFoGnf10yfPqhPhV2I38Vty7-wHJ6INHBaqXg',
    //         refreshToken: 'hj345ghjasdasdwer2123sdf',
    //         roleId: '1',
    //         permissions: [
    //             ...Object.values(permissions.forPages),
    //             ...Object.values(permissions.forSidebar),
    //         ],
    //         userId: 'US001',
    //         tokenExpiryTimestamp: new Date()
    //     }
    //     dispatch(authenticateAction(userAuthData))
    //     appHistory.push(routes.privatePages.DashboardPage.url())
    // }, [])

    return (
        <Router history={appHistory}>
            <ScrollToTop />
            {
                auth.token ? <PrivateRoutes /> : <PublicRoutes />
            }
        </Router>
    )
}

export default AppRouter
