import { PaletteOptions } from '@mui/material';
import { createTheme, Palette } from '@mui/material/styles';
import { TypographyOptions } from '@mui/material/styles/createTypography';


// Override Mui's theme typings to include the new theme property
declare module '@mui/material/styles' {
	// allow configuration using `createTheme`
	interface ThemeOptions {
		overrides?: {},
		typography?: TypographyOptions | ((palette: Palette) => TypographyOptions) | undefined
	}
}

export const sharedThemePalette: PaletteOptions = {
    primary: {
        main: '#FF5223',
		dark: '#FF5223',
		light: '#FF5223',
		contrastText: '#ffffff'
    },
    secondary: {
        main: '#FF1D92',
		dark: '#FF1D92',
		light: '#FF1D92',
		contrastText: '#ffffff'
    },
    error: {
        main: '#DB524E',
        dark: '#DB524E',
        light: '#DB524E',
        contrastText: '#F2CACA'
    },
    background: {
        default: '#FFFFFF',
		paper: '#FFFFFF'
    },
	custom: {
		base: '#FFE6D7',
		textPrimary: '#1A1A1A',
		subTextPrimary: '#3A3A3A',
		textMuted: '#8B8B8B',
		mutedPrimary: 'rgba(87, 94, 158, 50%)',
		gradiantPrimary: 'linear-gradient(90deg, #FF5223 2.16%, #FF1D92 99.99%, rgba(217, 217, 217, 0) 100%);',
		gradiantSecondary: 'linear-gradient(0deg, rgba(254,217,21,1) 0%, rgba(255,131,168,1) 100%);'
	}
}

const typography: TypographyOptions = {
	fontFamily: [
		'Inter',
		'sans-serif',
	].join(','),

	h1: {
		fontFamily: 'Inter, sans-serif',
		fontSize: 32,
		fontWeight: 600,
	},
	h2: {
		fontFamily: 'Inter, sans-serif',
	},
	h3: {
		fontFamily: 'Inter, sans-serif',
	},
	h4: {

		fontFamily: 'Inter, sans-serif',
	},
	h5: {
		fontFamily: 'Inter, sans-serif',
	},
	h6: {
		fontFamily: 'Inter, sans-serif',
	},
	body1: {
		fontFamily: 'Inter, sans-serif',
		fontSize: 14
	},
	body2: {
		fontFamily: 'Inter, sans-serif',
		fontSize: 14
	},
	button: {
		fontFamily: 'Inter, sans-serif',
		textTransform: 'none',
	}
}

const overrides = {
	MuiInputLabel: {
		root: {
			fontSize: 12
		}
	},
	MuiInput: {
		root: {
			fontSize: 14
		}
	},
	MuiFormControl: {
		root: {
			marginTop: 14
		}
	},
	'@global': {
		"*": {
			WebkitFontSmoothing: 'auto',
		},
	},
}


// Create a theme instance.
export const lightTheme = createTheme({
    overrides,
    typography,
    palette: {
        ...sharedThemePalette,
		mode: 'light'
    },
});

export const darkTheme = createTheme({
    overrides,
    typography,
    palette: {
        ...sharedThemePalette,
		primary: {
			main: '#FF5223',
			dark: '#FF5223',
			light: '#FF5223',
			contrastText: '#ffffff'
		},		
		secondary: {
			main: '#FF1D92',
			dark: '#FF1D92',
			light: '#FF1D92',
			contrastText: '#ffffff'
		},
        mode: 'dark',
        background: {
            paper: '#121212',
            default: '#121212'
        }
    },
});


export const offlineTheme = createTheme({
	overrides,
    typography,
    palette: {
        ...sharedThemePalette,
		primary: {
			main: '#000000',
			dark: '#000000',
			light: '#000000',
			contrastText: '#ffffff'
		},		
		secondary: {
			main: '#333333',
			dark: '#333333',
			light: '#333333',
			contrastText: '#ffffff'
		},
        mode: 'dark',
        background: {
            paper: '#111111',
            default: '#111111'
        }
    },
})