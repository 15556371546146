import axios from "axios";
import env from "../env";

const Axios = axios.create({
    baseURL: env.ADMIN_BACKEND_BASE_URL
});

export const ClientAxios = axios.create({
    baseURL: env.CLIENT_BACKEND_BASE_URL
});

export const AuthMicroService = axios.create({
    baseURL: env.ADMIN_BACKEND_BASE_URL
});

export const ZeronBackend = axios.create({
    baseURL: ''
});

export const cancelToken = axios.CancelToken

export default Axios;