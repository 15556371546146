import { FormControl, Input, InputLabel, InputLabelProps, InputProps, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import React, { ReactNode } from "react";
import { OnChangeEventHandler } from "../../../types";
import InputErrors from "./InputErrors";

const useStyles = makeStyles<Theme>((theme) => ({
    root: {
        marginBottom: theme.spacing(1),
    },
    input: {
        color: theme.palette.text.primary,

        borderRadius: theme.spacing(0.75),

        "& .MuiOutlinedInput-input": {
            padding: theme.spacing(1.5)
        },
        "&:before": {
            borderBottom: `1px solid ${theme.palette.text.primary} !important`,
        },
        "&:after": {
            borderBottomColor: theme.palette.text.primary,
            borderBottom: "none",
            background: `linear-gradient(45deg, ${theme.palette.primary.main} 20%, ${theme.palette.secondary.main}) 80%`,
            height: "2px",
        },
        "&.MuiInputLabel-asterisk": {
            color: theme.palette.secondary.main,
        },
        "&:hover": {
            "&:before": {
                borderBottom: `2px solid ${theme.palette.text.primary} !important`,
            },
        }
    },
    label: {
        color: theme.palette.text.primary,
        fontSize: '0.875rem',
        "&.MuiInputLabel-shrink": {
            color: theme.palette.text.primary,
        },
        "&.MuiInputLabel-asterisk": {
            color: theme.palette.secondary.main,
        },
    },
    labelForDate: {
        fontSize: 10,
    },
}));

type Variant = 'filled' | 'outlined' | 'standard'
type Type = 'text' | 'email' | 'number' | 'password' | 'date' | 'datetime-local'

interface Props {
    name: string,
    label?: string
    endAdornment?: ReactNode | undefined
    startAdornment?: ReactNode | undefined
    parentClassName?: string
    required?: boolean,
    variant?: Variant,
    multiline?: boolean,
    disabled?: boolean,
    value?: string | number,
    type?: Type
    inputClassName?: string,
    onChange?: OnChangeEventHandler
    inputProps?: InputProps
    inputLabelProps?: InputLabelProps
    hiddenLabel?: boolean,
    errors?: any
    onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined
}

const TextBox: React.FC<Props> = ({
    name,
    errors,
    disabled,
    endAdornment,
    startAdornment,
    value,
    type = "text",
    label = "",
    hiddenLabel = false,
    multiline = false,
    onChange = () => { },
    inputClassName,
    parentClassName,
    // labelClassName,
    inputProps,
    inputLabelProps,
    // labelForDate,
    // readOnly = false,
    required = false,
    variant = 'standard',
    onBlur
}) => {
    const classes = useStyles();

    return (
        <FormControl
            variant={variant}
            fullWidth
            required={required}
            className={clsx(classes.root, parentClassName)}
            disabled={disabled}
            hiddenLabel={hiddenLabel}
        >
            <InputLabel error={errors && errors[name]} {...inputLabelProps} htmlFor={name}>{label}</InputLabel>
            <Input
                error={errors && errors[name]}
                id={name}
                value={value || ''}
                onChange={onChange}
                name={name}
                startAdornment={startAdornment}
                endAdornment={endAdornment}
                aria-describedby="my-helper-text"
                multiline={multiline}
                type={type}
                className={inputClassName}
                onKeyPress={(e) => {
                    if (type === 'number') {
                        var charStr = e.key;
                        if (!charStr.match(/^\d+$/))
                            e.preventDefault();
                    }
                }}
                onBlur={onBlur}
                {...inputProps}

            />
            <InputErrors errors={errors} name={name} />
            {/* <FormHelperText id="my-helper-text">We'll never share your email.</FormHelperText> */}
        </FormControl>
    );
};

export default TextBox;
