import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { fadeColor } from '../../utils/styles';
import AppNavbar from './Navbar/AppNavbar';
import Sidebar from './Sidebar/Sidebar';


const drawerWidth = 240;

const useStyles = makeStyles<Theme>((theme) => ({
    root: {
        display: "flex",
        height: "100vh"
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px) !important`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: "none",
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: "nowrap",
        "& .MuiDrawer-paper": {
            // backgroundColor: fadeColor(theme.palette.background.paper, 0.465),
            position: "relative",

            '&:after': {
                content: "''",
                position: "absolute",
                right: "0px",
                top: 0,
                width: "2px",
                height: "100%",
                background: `linear-gradient(90deg,${fadeColor(theme.palette.primary.main, 0.15)}, ${fadeColor(theme.palette.secondary.main, 0.1)})`,
            },
        }
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: "hidden",
        width: 0,
        [theme.breakpoints.up("sm")]: {
            width: 0,
        },
    },
    toolbar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: theme.spacing(0, 1, 0, 2),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        "& .MuiSvgIcon-root": {
            color: theme.palette.primary.main
        }
    },
    content: {
        flexGrow: 1,
        // padding: theme.spacing(2, 3, 0, 3),
        overflow: "auto",
        zIndex: 1205,
        position: "relative",
    },
    listItem: {
        '& .MuiTypography-body1, & .MuiSvgIcon-root': {
            color: theme.palette.text.secondary,
        },
        '& .MuiTypography-body1': {
            fontSize: 14
        },
        ' & .MuiSvgIcon-root': {
            width: 18,
            height: 18
        },
        "&.MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover": {
            '& .MuiTypography-body1, & .MuiSvgIcon-root': {
                color: theme.palette.getContrastText(theme.palette.primary.main)
            },
            '&:after': {
                width: 5,
                height: 28,
                backgroundColor: theme.palette.getContrastText(theme.palette.secondary.main),
                content: '""',
                position: 'absolute',
                right: 0,
                display: 'block'
            }
        },
    },
    justifyContentCenter: {
        justifyContent: 'center'
    },
    navbarLogo: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        '& img': {
            height: 24,
        }
    }
}));


const PrivateBaseLayout: React.FC = ({ children }) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);

    const openSidebar = () => setOpen(true);
    const closeSidebar = () => setOpen(false);

    return (
        <div className={classes.root}>
            {/* <UpdateWorkspaceKeysModal /> */}
            <Sidebar
                classes={classes}
                openSidebar={open}
                setOpenSidebar={setOpen}
            />
            <main className={classes.content}>
                <AppNavbar
                    classes={classes}
                    sidebarIsOpen={open}
                    openSidebar={openSidebar}
                />
                <div className={classes.toolbar} />
                {children}
            </main>
        </div>
    )
}

export default PrivateBaseLayout
