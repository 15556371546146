import { stringifyUrl } from "query-string"
import { ClientAxios } from "../interceptors/axiosInstance"
import Request from "../interceptors/Request"
import { CreateEnterpriseDetailsReq, CreateReq, IGeneralResponseData, IGetInvoiceForFlexiblePlanPriceRequestBody, ISaveSignupUser, ItemPrice, Response } from "../types"

const PaymentService = new Request(ClientAxios)

export const createInvoice = (data: {
    couponCode: string
    duration: number
    email: string
    planId: string
    name: string
    phone: string
    organizationName: string
}) => {
    return PaymentService.post<typeof data, IGeneralResponseData>('/PlanBilling/createInvoice', data, { checkToken: false, headers: {}, axiosOptions: {} })
}

export const createInvoiceForFlexiblePlan = (data: IGetInvoiceForFlexiblePlanPriceRequestBody) => {
    return PaymentService.post<typeof data, IGeneralResponseData>('/PlanBilling/createInvoiceForFlexiblePlan', data, { checkToken: false, headers: {}, axiosOptions: {} })
}

export const getAllPlans = () => {
    return PaymentService.post<null, IGeneralResponseData>('/PlanBilling/getAllPlan', null, { checkToken: false, headers: {}, axiosOptions: {} })
}

export const getAllActivePlans = (type: 'PLAN' = 'PLAN', limit: number = 100) => {
    return PaymentService.post<null, IGeneralResponseData>(`/Item/getAll?limit=${limit}&type=${type}`, null, { checkToken: false, headers: {}, axiosOptions: {} })
}

export const getAllFlexiblePlans = () => {
    return PaymentService.post<null, IGeneralResponseData>('/PlanBilling/getAllFlexiblePlan', null, { checkToken: false, headers: {}, axiosOptions: {} })
}

export const updateOrder = (data: {invoiceId: string}) => {
    return PaymentService.post<typeof data, IGeneralResponseData>('/PlanBilling/updateOrder', data, { checkToken: false, headers: {}, axiosOptions: {} })
}

export const getInvoicePrice = (data: {
    couponCode: string
    duration: number
    email: string
    planId: string
}) => {
    return PaymentService.post<typeof data, IGeneralResponseData>('/PlanBilling/getInvoicePrice', data, { checkToken: false, headers: {}, axiosOptions: {} })
}

export const getPlanPriceByItemId = (id: string) => {
    return PaymentService.post<null,Response<ItemPrice[]>>(`/ItemPrice/getByItemId?limit=100&itemId=${id}`, null, { checkToken: false, headers: {}, axiosOptions: {} })
}

export const getByItemIdCurrencyPeriod = (data: {
    itemId: string
    currency: string
    limit: number
    period: number
    periodUnit: string
}) => {
    return PaymentService.post<null,Response<ItemPrice[]>>(stringifyUrl({url: `/ItemPrice/getByItemIdCurrencyPeriod`, query: data}), null, { checkToken: false, headers: {}, axiosOptions: {} })
}

export const getInvoiceForFlexiblePlanPrice = (data: IGetInvoiceForFlexiblePlanPriceRequestBody) => {
    return PaymentService.post<typeof data,IGeneralResponseData >('/PlanBilling/getInvoiceForFlexiblePlanPrice', data, { checkToken: false, headers: {}, axiosOptions: {} })
}

export const saveSignupUser = (data: ISaveSignupUser) => {
    return PaymentService.post<typeof data, { payload: any }>('/PlanBilling/createUserDetails', data, { checkToken: false, headers: {}, axiosOptions: {} })
}

export const createEnterpriseDetails = (data: CreateEnterpriseDetailsReq) => {
    return PaymentService.post<typeof data, { payload: any }>('/Enterprise/createEnterpriseDetails', data, { checkToken: false, headers: {}, axiosOptions: {} })
}

export const createReq = (data: CreateReq) => {    
    return PaymentService.post<typeof data, { payload: any }>('/BuyRequest/createReq', data, { checkToken: false, headers: {}, axiosOptions: {} })
}

export const createCustomer = (reqId: string) => {
    return PaymentService.post<null, { payload: any }>(`/Customer/create?reqId=${reqId}`, null, { checkToken: false, headers: {}, axiosOptions: {} })
}

export const createHostedUi = (reqId: string, couponCodes: string[]) => {
    return PaymentService.post<typeof couponCodes, { payload: any }>(`/HostedUi/create?reqId=${reqId}`, couponCodes, { checkToken: false, headers: {}, axiosOptions: {} })
}