import { Action, IPrivateMasterdataState } from "../../types";
import { masterdataConstants } from "../constants/masterdata";

const initialState: IPrivateMasterdataState = {
    allPlans: [],
    allEvents: [],
}

const privateMasterdataReducer = (state = initialState, action: Action): IPrivateMasterdataState => {
    switch (action.type) {
        case masterdataConstants.FETCH_PRIVATE_MASTER_DATA:
            return {
                ...state,
                ...action.payload,
            }
        case masterdataConstants.CLEAR_PRIVATE_MASTER_DATA: 
            return initialState
        default:
            return state;
    }
}

export default privateMasterdataReducer;