/* 
    All functions that will help to style common components via theme.
*/
import { colord } from "colord";

export const colorManipulator = (color: string) => colord(color)

// export const fadeAscentColor = (theme: Theme, fade = 0.85) => (color: string) => {

//     let paletteColor = theme.palette[color].main;

//     if (paletteColor) {
//         return ((colorManipulator(paletteColor)).lighten(fade).string());
//     }
// }


export const fadeColor = (color: string, fade = 0.85) => {
    return ((colorManipulator(color)).lighten(fade).toHex());
}

export const lightenColor = (color: string, alpha = 0.85) => {
    return ((colorManipulator(color)).lighten(alpha).toHex());
}

export const darkenColor = (color: string, alpha = 0.85) => {
    return ((colorManipulator(color)).darken(alpha).toHex());
}

export const setColorOpacity = (color: string, alpha = 0.85) => {
    return ((colorManipulator(color)).alpha(alpha).toHex());
}