import { AnimatePresence } from 'framer-motion'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Switch, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import routes from '../../constants/routes'
import { logoutDispatcher } from '../../redux/dispatchers/authDispatchers'
import { RootState } from '../../types'
import PrivateBaseLayout from '../common/PrivateBaseLayout'
import { RenderRoute } from './AppRouter'


const PrivateRoutes: React.FC = () => {
    const location = useLocation()
    const userPermissions = useSelector((state: RootState) => state.auth.permissions)
    const tokenExpiryTimestamp = useSelector((state: RootState) => state.auth.tokenExpiryTimestamp)
    const dispatch = useDispatch()

    const handleNotifyUserToLogout = () => {
        if (tokenExpiryTimestamp) {
            const currentTimestamp = (new Date()).getTime()
            if (currentTimestamp > tokenExpiryTimestamp) {
                dispatch(logoutDispatcher(routes.publicPages.LoginPage.url()))
            } else {
                setTimeout(() => {
                    toast.error('Your session has ended! Login again to continue.', {
                        autoClose: false,
                        theme: 'colored',
                        position: 'bottom-center'
                    })
                }, tokenExpiryTimestamp - currentTimestamp)
            }
        }
    }

    React.useEffect(() => {
        handleNotifyUserToLogout()
    }, [tokenExpiryTimestamp])

    return (
        <PrivateBaseLayout>
            <AnimatePresence exitBeforeEnter>
                <Switch location={location} key={location.pathname}>
                    {
                        Object.values(routes.privatePages)
                            .filter(page => userPermissions.includes(page.permissionKey))
                            .map(RenderRoute)
                    }
                </Switch>
            </AnimatePresence>
        </PrivateBaseLayout>
    )
}

export default PrivateRoutes
