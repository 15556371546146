import { CircularProgress, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles<Theme>((theme) => ({
    root: {
        marginRight: theme.spacing(2),
        "& .MuiCircularProgress-svg": {
            color: theme.palette.primary.main,
        }
    },

}));

export default function CircularLoader({ ...props }) {
    const classes = useStyles();

    return (
        <CircularProgress className={classes.root} {...props} />
    );
}
