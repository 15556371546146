import copy from 'copy-to-clipboard';
import { nanoid } from 'nanoid';
import { toast } from 'react-toastify';
import env from '../env';
import { CurrencyType } from '../types';

/**
 * @desc Used for assigning unique ids
 * @param size length of generated id (default 21)
 * @returns string
 * @see https://www.npmjs.com/package/nanoid
 * @example 
 * "V1StGXR8_Z5jdHi6B-myT"
 */
export const getUuid = (size = 21) => nanoid(size)


// ?Converts 'user/:user_id/group/:id/role/:roleId' => 'user/1/group/21/role/3'
export const replaceVariableWithValueInURL = (url: string, values: string[] = []) => {

    if (!url) {
        return "";
    }

    // regex => `:anyVar1able`
    // eslint-disable-next-line no-useless-escape
    const regex = /\:[a-zA-Z0-9\_]*/;

    let newUrl = url.concat("");

    for (let i = 0; i < values.length; i++) {

        const item = values[i];

        const match = regex.exec(newUrl);

        if (match === null) {
            return newUrl;
        }
        newUrl = newUrl.replace(match[match.length - 1], item)
    }

    return newUrl;

}

export const preferredCurrencyFormat = (number: CurrencyType, preferredCurrencyCode?: string) => {
    return (!!number || number === 0) ? new Intl.NumberFormat('en-IN', {
        style: preferredCurrencyCode ? 'currency' : undefined,
        currency: preferredCurrencyCode || undefined,
        minimumFractionDigits: 0,
    }).format(number) : ``
}

export const formatCurrency = (number: CurrencyType, hideSymbol = false) => {
    return new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 0 }).format((!!number || number === 0) ? number : 0)
}

export const hasSpecialChars = (str: string) => str.match(/[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#]/)

export const handleCopyToClipboard = (data: string, msg: string = 'Data has been copied to clipboard') => () => {
    copy(data)
    toast.success(msg)
}

export const urlEncode = function (data: any) {
    var str = [];
    for (var p in data) {
        if (
            data.hasOwnProperty(p) &&
            !(data[p] == undefined || data[p] == null)
        ) {
            str.push(
                encodeURIComponent(p) +
                "=" +
                (data[p] ? encodeURIComponent(data[p]) : "")
            );
        }
    }
    return str.join("&");
};

export const getValueOrPlaceholder = (value: string | number) => value !== 0 ? (value || '---') : 0

export const getMediaUrl = (path: string) => env.STRAPI_HOST_URL.concat(path)