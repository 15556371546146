const permissions = {
    forPages: {
        HomePage: 'HomePage',
        DashboardPage: 'DashboardPage',
        AnalyticsPage: 'AnalyticsPage',
        DemoPage: 'DemoPage',
        PageNotFound: 'PageNotFound',
        LoginPage: 'LoginPage',
        AllBlogsPage: 'AllBlogsPage',
        BlogDetailsPage: 'BlogDetailsPage'
    },

    forSidebar: {
        General: 'General',
        Spaces: 'Spaces',
        Roles: 'Roles',
        Misc: 'Misc'
    }

}

export default permissions;