import CloseIcon from '@mui/icons-material/Close'
import ContactMailIcon from '@mui/icons-material/ContactMail'
import EngineeringIcon from '@mui/icons-material/Engineering'
import HomeIcon from '@mui/icons-material/Home'
import InfoIcon from '@mui/icons-material/Info'
import MenuIcon from '@mui/icons-material/Menu'
import MenuBookIcon from '@mui/icons-material/MenuBook'
import { AppBar, Container, IconButton, ListItemIcon, ListItemText, MenuItem, MenuList, SwipeableDrawer, Theme, Toolbar, useTheme } from '@mui/material'
import { makeStyles } from "@mui/styles"
import { Box } from '@mui/system'
import { FC, RefObject, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useLocation, useWindowScroll, useWindowSize } from 'react-use'
import { images } from '../../../constants/assets'
import { NAVBAR_HASHES } from '../../../constants/other'
import routes from '../../../constants/routes'
import { setColorOpacity } from '../../../utils/styles'
import Text from '../Text'

interface StyleProps {
    isDarkNavbar: boolean
}

const useStyles = makeStyles<Theme, StyleProps>(theme => ({
    appBar: {
        background: ({ isDarkNavbar }) => isDarkNavbar ? setColorOpacity(theme.palette.common.black, 0.85) : theme.palette.custom.base,
        boxShadow: ({ isDarkNavbar }) => isDarkNavbar ? '0 0 20px 0 #4e4e4e' : 'none',
        zIndex: 9999
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(2, 0),
    },
    navMenus: {
        display: 'flex',
    },
    logo: {
        maxWidth: '3rem',
        height: 'auto',
        cursor: 'pointer',

        [theme.breakpoints.down('sm')]: {
            maxWidth: '2rem'
        }
    },
    link: {
        position: 'relative',
        cursor: 'pointer',
        color: ({ isDarkNavbar }) => isDarkNavbar ? theme.palette.common.white : theme.palette.text.primary

        // '&:hover:after': {
        //     content: '""',
        //     display: 'block',
        //     position: 'absolute',
        //     bottom: -8,
        //     left: 0,
        //     width: '50%',
        //     height: 3,
        //     background: theme.palette.custom.gradiantPrimary,
        //     borderRadius: 100
        // }
    },
    menuBtn: {
        '& svg': {
            color: ({ isDarkNavbar }) => isDarkNavbar ? theme.palette.common.white : theme.palette.custom.textPrimary
            // width: 18,
            // height: 18,
        }
    },
    drawer: {
        '& .MuiDrawer-paper': {
            minWidth: 250,
            backgroundColor: theme.palette.custom.base
        },
        zIndex: 99999
    },
    drawerMenu: {
        '& .MuiSvgIcon-root': {
            color: theme.palette.custom.textPrimary
        }
    }
}))
type Anchor = 'top' | 'left' | 'bottom' | 'right';

interface Props {
    heroSectionRef?: RefObject<HTMLElement>
    aboutSectionRef?: RefObject<HTMLElement>
    servicesSectionRef?: RefObject<HTMLElement>
    contactUsSectionRef?: RefObject<HTMLElement>
    isHomePage?: boolean
}

const PublicNavbar: FC<Props> = ({
    isHomePage = false,
    heroSectionRef,
    aboutSectionRef,
    servicesSectionRef,
    contactUsSectionRef,
}) => {
    const history = useHistory()
    const { y } = useWindowScroll()
    const isDarkNavbar = y > 200
    const classes = useStyles({ isDarkNavbar })
    const { width } = useWindowSize()
    const theme = useTheme()
    const [anchorDirection] = useState<Anchor>('right')
    const [openDrawer, setOpenDrawer] = useState(false)
    const location = useLocation()

    const handleOnNavLinkClick = (elemRef: RefObject<HTMLElement> | undefined) => () => {
        if (elemRef && elemRef.current) {
            const headerOffset = elemRef.current?.offsetHeight;
            const elementPosition = elemRef.current.getBoundingClientRect().bottom;
            const offsetPosition = elementPosition + window.pageYOffset - headerOffset - 81 - (y > theme.breakpoints.values.md ? 24.671 : 0);

            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
            });
        }

        if (openDrawer) {
            toggleDrawer(false)()
        }
    }

    const toggleDrawer = (open: boolean) => () => {
        setOpenDrawer(open)
    };

    const handleNavigate = (url: string) => () => {
        history.push(url)
    }

    const navigateToHash = () => {
        const hash = location.hash
        switch (hash) {
            case NAVBAR_HASHES.HOME:
                handleOnNavLinkClick(heroSectionRef)()
                break;
            case NAVBAR_HASHES.ABOUT:
                handleOnNavLinkClick(aboutSectionRef)()
                break;
            case NAVBAR_HASHES.SERVICES:
                handleOnNavLinkClick(servicesSectionRef)()
                break;
            case NAVBAR_HASHES.CONTACT_US:
                handleOnNavLinkClick(contactUsSectionRef)()
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        if (heroSectionRef && aboutSectionRef && servicesSectionRef && contactUsSectionRef && isHomePage) {
            navigateToHash()
        }
    }, [heroSectionRef, aboutSectionRef, servicesSectionRef, contactUsSectionRef])


    return (
        <AppBar elevation={0} position='sticky' className={classes.appBar}>
            <Container>
                <Toolbar className={classes.toolbar}>
                    <img onClick={isHomePage ? handleOnNavLinkClick(heroSectionRef) : handleNavigate(routes.publicPages.HomePage.url())} className={classes.logo} src={images[!isDarkNavbar ? 'FullDarkLogo' : 'FullLightLogo']} alt='logo' />
                    {
                        width > theme.breakpoints.values.sm ?
                            <Box className={classes.navMenus}>
                                {/* <Text onClick={isHomePage ? handleOnNavLinkClick(heroSectionRef) : handleNavigate(routes.publicPages.HomePage.url())} className={classes.link}>Home</Text> */}
                                <Text onClick={isHomePage ? handleOnNavLinkClick(aboutSectionRef) : handleNavigate(routes.publicPages.HomePage.url().concat(NAVBAR_HASHES.ABOUT))} className={classes.link} ml={4}>About</Text>
                                <Text onClick={isHomePage ? handleOnNavLinkClick(servicesSectionRef) : handleNavigate(routes.publicPages.HomePage.url().concat(NAVBAR_HASHES.SERVICES))} className={classes.link} ml={4}>Services</Text>
                                <Text onClick={handleNavigate(routes.publicPages.AllBlogsPage.url())} className={classes.link} ml={4}>Blogs</Text>
                                <Text onClick={isHomePage ? handleOnNavLinkClick(contactUsSectionRef) : handleNavigate(routes.publicPages.HomePage.url().concat(NAVBAR_HASHES.CONTACT_US))} className={classes.link} ml={4}>Contact Us</Text>
                            </Box> :
                            (
                                <>
                                    <IconButton onClick={toggleDrawer(true)} className={classes.menuBtn}>
                                        <MenuIcon />
                                    </IconButton>
                                    <SwipeableDrawer
                                        className={classes.drawer}
                                        anchor={anchorDirection}
                                        open={openDrawer}
                                        onClose={toggleDrawer(false)}
                                        onOpen={toggleDrawer(true)}
                                    >
                                        <Box py={1} px={1}>
                                            <MenuList className={classes.drawerMenu}>
                                                <MenuItem sx={{ display: 'flex', justifyContent: 'flex-end' }} onClick={toggleDrawer(false)}>
                                                    <IconButton>
                                                        <CloseIcon fontSize="small" />
                                                    </IconButton>
                                                </MenuItem>
                                                <MenuItem onClick={isHomePage ? handleOnNavLinkClick(heroSectionRef) : handleNavigate(routes.publicPages.HomePage.url().concat('#home'))}>
                                                    <ListItemIcon>
                                                        <HomeIcon fontSize="small" />
                                                    </ListItemIcon>
                                                    <ListItemText>Home</ListItemText>
                                                </MenuItem>
                                                <MenuItem onClick={isHomePage ? handleOnNavLinkClick(aboutSectionRef) : handleNavigate(routes.publicPages.HomePage.url().concat(NAVBAR_HASHES.ABOUT))}>
                                                    <ListItemIcon>
                                                        <InfoIcon fontSize="small" />
                                                    </ListItemIcon>
                                                    <ListItemText>About</ListItemText>
                                                </MenuItem>
                                                <MenuItem onClick={isHomePage ? handleOnNavLinkClick(servicesSectionRef) : handleNavigate(routes.publicPages.HomePage.url().concat(NAVBAR_HASHES.SERVICES))}>
                                                    <ListItemIcon>
                                                        <EngineeringIcon fontSize="small" />
                                                    </ListItemIcon>
                                                    <ListItemText>Services</ListItemText>
                                                </MenuItem>
                                                <MenuItem onClick={handleNavigate(routes.publicPages.AllBlogsPage.url())}>
                                                    <ListItemIcon>
                                                        <MenuBookIcon fontSize="small" />
                                                    </ListItemIcon>
                                                    <ListItemText>Blogs</ListItemText>
                                                </MenuItem>
                                                <MenuItem onClick={isHomePage ? handleOnNavLinkClick(contactUsSectionRef) : handleNavigate(routes.publicPages.HomePage.url().concat(NAVBAR_HASHES.CONTACT_US))}>
                                                    <ListItemIcon>
                                                        <ContactMailIcon fontSize="small" />
                                                    </ListItemIcon>
                                                    <ListItemText>Contact Us</ListItemText>
                                                </MenuItem>

                                            </MenuList>
                                        </Box>
                                    </SwipeableDrawer>
                                </>
                            )
                    }
                </Toolbar>
            </Container>
        </AppBar >
    )
}

export default PublicNavbar