import { Box, Container, Grid, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FC, RefObject } from "react";
import { Animated } from "react-animated-css";
import MusicVideosImg from '../../../assets/images/services-section/1.png';
// import StageShowImg from '../../../assets/images/services-section/2.png';
// import FashionShowImg from '../../../assets/images/services-section/3.jpg';
import OwnMovieImg from '../../../assets/images/services-section/4.png';
import LineProductionImg from '../../../assets/images/services-section/5.png';
import TvShowImg from '../../../assets/images/services-section/6.png';
import DigitalMarketingImg from '../../../assets/images/services-section/7.png';
import ITSupportImg from '../../../assets/images/services-section/8.png';
import useOnScreen from "../../../hooks/useOnScreen";
import { setColorOpacity } from "../../../utils/styles";
import Text from "../../common/Text";
import SectionTitle from "./SectionTitle";

/**
import MusicVideosImg from '../../../assets/images/services-section/1.png';
import StageShowImg from '../../../assets/images/services-section/2.png';
import FashionShowImg from '../../../assets/images/services-section/3.png';
import OwnMovieImg from '../../../assets/images/services-section/4.png';
import LineProductionImg from '../../../assets/images/services-section/5.png';
import TvShowImg from '../../../assets/images/services-section/6.png';
 */

interface StyleProps {
    title?: string
    bgImg?: string
}

const useStyles = makeStyles<Theme, StyleProps>(theme => ({
    root: {
        padding: theme.spacing(19, 0),

        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(8, 0),
        },
        position: 'relative',
        zIndex: 9,
        backgroundColor: '#fff'
    },
    grid: {
        // padding: theme.spacing(0, 25),
        marginTop: theme.spacing(10),

        '& .MuiGrid-item': {
            padding: theme.spacing(2),

            '& img': {
                maxWidth: '100%',
                height: 'auto'
            }
        },

        [theme.breakpoints.down('md')]: {
            marginTop: theme.spacing(8),
            padding: theme.spacing(0),

            '& .MuiGrid-item': {
                padding: theme.spacing(0.5),
            }
        }
    },
    overlayParent: {
        position: 'relative',
        width: '100%',
        paddingTop: `calc(100%)`,
    },
    overlay: {
        position: 'absolute',
        backgroundImage: ({ bgImg }) => `url(${bgImg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        padding: theme.spacing(3),


        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(1),
        },

        '& .title': {
            visibility: 'hidden',
            zIndex: 999,
            fontSize: 20,
            fontWeight: 600,
            textAlign: 'center',

            [theme.breakpoints.down('md')]: {
                fontSize: 14
            }
        },
        '& .subtitle': {
            visibility: 'hidden',
            zIndex: 999,
            textAlign: 'center',
            marginTop: 16,
            lineHeight: '28px',

            [theme.breakpoints.down('md')]: {
                marginTop: 4,
                fontSize: 10,
                lineHeight: '12px',
            }
        },
        '&:hover .title, &:hover .subtitle': {
            visibility: 'visible',
        },

        '&:hover:before': {
            content: '""',
            backgroundColor: setColorOpacity(theme.palette.custom.base, 0.85),
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
        },
    }
}))

const Overlay: FC<{
    title: string
    subtitle: string
    img: string
}> = ({
    subtitle,
    title,
    img,
}) => {
        const classes = useStyles({
            title,
            bgImg: img
        })

        return (
            <Box className={classes.overlayParent}>
                <Box className={classes.overlay}>
                    <Text className="title">{title}</Text>
                    <Text className="subtitle">{subtitle}</Text>
                </Box>
            </Box>
        )
    }

interface Props {
    innerRef: RefObject<HTMLElement>
}

export const services: {
    title: string
    subtitle: string
    img: string
}[] = [
        {
            title: 'Line production',
            subtitle: 'We avail to set all operations and logistics for a film, from the pre-production phase through release of the completed work. Our crew will allocate the money and make sure that the filming is done safely, creatively, on budget and on time.',
            img: LineProductionImg
        },
        // {
        //     img: StageShowImg,
        //     title: 'Stage shows',
        //     subtitle: 'ProReal offers you to keep the precious commenting performances, with all the endless rehearsals and the learning of lines and movements. We ensure that the cast with their families and friends can enjoy and relive the experience over the years.'
        // },
        // {
        //     img: FashionShowImg,
        //     title: 'Fashion shows',
        //     subtitle: 'Embody the passion and pizzazz of your fashion brand through beautiful fashion video production with us. We will help you to live stream your runway shows and capture the elegance of your launch events with equally elegant video sizzles and teasers.'
        // },
        {
            img: TvShowImg,
            title: 'TV shows/Drama',
            subtitle: 'We produce various Indian soap operas, reality TV, comedy, game shows, entertainment, and factual programming in several Indian languages.'
        },
        {
            img: MusicVideosImg,
            title: 'Music videos/shows',
            subtitle: 'Proreal produces music video projects from inception to delivery. Create exciting fresh concepts with our team of directors, writers, and visual researchers.'
        },
        {
            img: OwnMovieImg,
            title: 'Make your own Movie',
            subtitle: 'We use the visual media and cutting edge to create new experiences and open new doors towards sea of opportunities. Our expert team of storytellers will listen to your concept, guide you through the process and make the perfect movie for you.'
        },
        {
            img: DigitalMarketingImg,
            title: 'Digital Marketing',
            subtitle: 'Up your reach sky high with digital platforms including social media with notch content creation. We make sure you have a personal branding with a push by verified influencer marketing with 360° customer reach out no matter where you are! and automated marketing helps you to free up time-hassles.'
        },
        {
            img: ITSupportImg,
            title: 'IT Support',
            subtitle: 'Keep up your working system clean and clear with our IT Support engaging Cyber Security. Also, if you are looking for an start, we are here with Website development and management. Start owning your own service app only with Proreal.'
        },

    ]

const OurServicesSection: FC<Props> = ({
    innerRef
}) => {
    const onScreen = useOnScreen(innerRef, "-180px");
    const classes = useStyles({})

    return (
        <Box ref={innerRef} className={classes.root} component='section' id='ourServicesSection'>
            <Container>
                <Animated animationIn="fadeInUp" animationOut="fadeInUp" isVisible={onScreen} animateOnMount={false}>
                    <SectionTitle
                        headingTitle="services"
                        titleLine1=" Offering several extensive "
                        titleLine2="services for you"
                    />
                </Animated>
                <Grid container className={classes.grid}>
                    {
                        services.map((service, index) => (
                            <Grid key={service.title} item xs={6} lg={4} className='service'>
                                <Animated animationIn="fadeInUp" animationOut="fadeInUp" isVisible={onScreen} animateOnMount={false} animationInDelay={500 + (index * 500)}>
                                    <Overlay title={service.title} subtitle={service.subtitle} img={service.img} />
                                </Animated>
                            </Grid>
                        ))
                    }
                </Grid>
            </Container>
        </Box>
    )
}

export default OurServicesSection
