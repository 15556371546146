import {
	QueryClient,
	QueryClientProvider
} from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
import { ParallaxProvider } from 'react-scroll-parallax';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { PersistGate } from 'redux-persist/integration/react';
import AppTheme from './components/common/Theme/AppTheme';
import MasterdataWrapper from './components/common/Wrappers/MasterdataWrapper';
import AppRouter from './components/router/AppRouter';
import { persistor, store } from './redux';

function App() {
	const queryClient = new QueryClient()

	return (
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<QueryClientProvider client={queryClient}>
					<MasterdataWrapper>
						<AppTheme>
							<ParallaxProvider>
								<AppRouter />
							</ParallaxProvider>
							<ToastContainer
								position="bottom-left"
								limit={3}
							/>
						</AppTheme>
					</MasterdataWrapper>
					<ReactQueryDevtools initialIsOpen={false} />
				</QueryClientProvider>
			</PersistGate>
		</Provider>
	);
}

export default App;
