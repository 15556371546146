import clsx from 'clsx'
import React from 'react'
import Text from '../components/common/Text'
import { images } from '../constants/assets'
import useCommonStyles from '../styles/useCommonStyles'

const PageNotFound = () => {
    const { width100, flex, flexColumn, width50, alignCenter, textCenter, marginVertical2 } = useCommonStyles()

    return (
        <div className={clsx(width100, flex, flexColumn, alignCenter, marginVertical2)}>
            <img src={images.NotFound} alt='not found' className={width50} />
            <div className={textCenter}>
                <Text variant='h1'>Page Not Found!</Text>
                <Text variant='h6'>The page you are looking for must have been removed.</Text>
            </div>
        </div>
    )
}

export default PageNotFound
