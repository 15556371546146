import axios from "axios";
import { stringifyUrl } from "query-string";
import Axios, { AuthMicroService, ZeronBackend } from "../interceptors/axiosInstance";
import Request from "../interceptors/Request";
import { DataIdType, IBookDemoFormData, IDateRange, IGeneralResponseData, ILoginFormData, ILoginResponseData, ISearchUserDetailsData } from "../types";
import { formatDateTimeWithZeroOffset } from "../utils/datetime";

const UserService = new Request(Axios)
const AuthService = new Request(AuthMicroService)
const ZeronBackendService = new Request(ZeronBackend)

export const getAllUsers = () => {
    const { token, cancel } = axios.CancelToken.source()
    return {
        api: () => UserService.get<{ name: string, email: string }[]>('/auth/login', {}, {
            cancelToken: token
        }),
        cancel
    }
}


export const getAllUsersWithoutCancelToken = () => {
    return UserService.get<{ name: string, email: string }[]>('/users')
}

export const login = (data: ILoginFormData) => {
    return AuthService.post<typeof data, ILoginResponseData>('/auth/login', data, { checkToken: false, headers: {}, axiosOptions: {} })
}

export const deletePostById = (id: DataIdType) => {
    return UserService.delete<{}[]>(`/posts/${id}`)
}

export const zeronSignup = (data: IBookDemoFormData) => {
    return ZeronBackendService.post<typeof data, { token: string }>('/rest/v1/signup/', data, { checkToken: false, headers: {}, axiosOptions: {} })
}

export const getAllDemoUsers = () => {
    return UserService.post<ISearchUserDetailsData, IGeneralResponseData>('/user/searchUserDetails', {
        "createdAt": (new Date()).getTime(),
        "email": "",
        "firstName": "",
        "lastName": "",
        "organisationName": ""
    })
}

export const getAllDemoUsersByDateRange = (data: (IDateRange & {status: number})) => {
    data.fromDate = formatDateTimeWithZeroOffset(data.fromDate, 'startOf')
    data.toDate = formatDateTimeWithZeroOffset(data.toDate, 'endOf')
    return UserService.post<typeof data, IGeneralResponseData>(stringifyUrl({ url: '/user/getAllUserDetailsByDateRange', query: {...data} }), data) 
}


export const updateDemoUserStatus = (data: {id: string, status: number}) => {
    return UserService.post<typeof data, IGeneralResponseData>(stringifyUrl({ url: '/user/updateUserDetailsStatus', query: {...data} }), data) 
}