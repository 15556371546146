import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import privateMasterdataDispatcher from '../../../redux/dispatchers/privateMasterdataDispatcher'
import { RootState } from '../../../types'

const MasterdataWrapper: React.FC = ({
    children
}) => {
    const dispatch = useDispatch()
    const token = useSelector((state: RootState) => state.auth.token)

    // React.useEffect(() => {
    //     dispatch(publicMasterdataDispatcher())
    // }, [])

    React.useEffect(() => {
        if (token) {
            dispatch(privateMasterdataDispatcher())
        }
    }, [token])

    return (
        <>
            {children}
        </>
    )
}

export default MasterdataWrapper
